  <template>
  <div class="container">
    <div class="abiturient-orders mt-4">
      <h4 style="text-align: center">Приказы</h4>


      <AddAbiturientOrdersItemModal/>

      <!-- Delete Abit Order Modal -->
      <div class="modal fade" id="deleteAbiturientOrdersModal" tabindex="-1"
           aria-labelledby="deleteAbiturientOrdersModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteAbiturientOrdersModalLabel">Удаление приказа</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mt-4">
                <p>Вы точно хотите удалить этот приказ?</p>


              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="deleteAbiturientOrders()">
                  Удалить
                </button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                  Отменить
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->


      <!-- Add Abit Order Modal -->
      <div class="modal fade" id="addAbiturientOrdersModal" tabindex="-1"
           aria-labelledby="addAbiturientOrdersModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addAbiturientOrdersModalLabel">Добавление приказа</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="col-md-12 mt-4">

                <div class="form-group row mt-4">
                  <label for="order_number" class="col-md-3 col-form-label">Номер приказа</label>
                  <div class="col-md-9">
                    <input id="order_number" type="text" class="form-control"
                           placeholder="Номер приказа" @input="changeAbiturientOrdersData( 'order_number', $event)">
                  </div>
                </div>

                <div class="form-group row mt-4">
                  <label for="academic_year" class="col-md-3 col-form-label">Учебный год</label>
                  <div class="col-md-9">
                    <select class="form-control form-select" name="academic_year" id="academic_year"
                            @input="changeAbiturientOrdersData( 'sp_academic_year_id', $event)">
                      <option
                          v-for="(item, index) in [{id: 0, name: 'Выберите учебный год'}, ...orders_form.academicYear]"
                          :value="item.id"
                          :key="index">{{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>


                <div class="form-group row mt-4">
                  <label for="order_date" class="col-md-3 col-form-label">Дата приказа</label>
                  <div class="col-md-9">
                    <input id="order_date" type="date" class="form-control"
                           @input="changeOrderDate('order_date', $event)">
                  </div>
                </div>


              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                        @click="submitAbiturientOrders()">
                  Добавить
                </button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                  Отменить
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End Modal -->

      <div class="row mt-4">
        <div class="col-md-12">
          <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                  data-bs-target="#addAbiturientOrdersModal">
            <i class="fas fa-plus-circle" aria-hidden="true"></i>&nbsp;Добавить
          </button>
        </div>
      </div>

      <!-- table start -->
      <div class="table-responsive-md mt-4">
        <table class="table table-striped table-bordered">
          <thead>
          <tr>
            <th scope="col">№</th>
            <th scope="col">Название приказа</th>
            <th scope="col">Учебный год {{currentSpAcademicYear}}
              <Dropdown v-model="filterSpAcademicYear" :options="orders_form.academicYear" optionLabel="name" optionValue="id" placeholder="Год"/>
            </th>
            <th scope="col">Дата приказа</th>
            <th scope="col">Параграф</th>
            <th scope="col">События</th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item,index) in orders_form.abiturientOrders"
              :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              {{ item.order_number }}
            </td>
            <td>
              {{ orders_form.academicYear.find(i => i.id === item.sp_academic_year_id).name }}
            </td>
            <td>
              {{ convertTimestampToDate(item.order_date) }}
            </td>
            <td>
              <a href="#" data-bs-toggle="modal" data-bs-target="#addAbiturientOrdersItemModal"
                 @click="openAddAbiturientOrdersItemModal(item.id)">Добавить параграф</a>
              <br>
              <ul id="item-list">
                <li v-for="(item, index) in item_form.abiturientOrdersItem.filter(i=>i.abit_orders_id == item.id)"
                    :key="index">

                  <router-link
                      :to="'/abiturient-list?abit_orders_item_id=' + item.id + '&payment_type='+ item.paymentType.short_name+'&discount='+item.discount
                        + '&pre_university='+ item.pre_university">
                    Параграф {{ index + 1 }}
                  </router-link>

                </li>
              </ul>

            </td>

            <td>
              <button type="button" class="btn btn-danger m-1" data-bs-toggle="modal"
                      data-bs-target="#deleteAbiturientOrdersModal"
                      @click="openDeleteAbiturientOrdersModal(item.id)">
                <i class="fa fa-trash-o" aria-hidden="true"></i>
              </button>

              <a :href="'https://back.uib.kz/api/user/report?id='+item.id" target="_blank" class="btn btn-primary m-1">
                <i class="far fa-file-pdf"></i>
              </a>

            </td>


          </tr>
          </tbody>
        </table>

      </div>
      <!-- table end -->


    </div>
  </div>


</template>

<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";
import {convertTimestampToDate} from "@/utils/helpers/convertTimestampToDate"
import AddAbiturientOrdersItemModal from '@/components/abiturient/AddAbiturientOrdersItemModal.vue'

export default {
  name: "AbiturientOrders",
  components: {
    AddAbiturientOrdersItemModal
  },

  data() {
    return {
      deleteAbiturientOrdersId: null,
      filterSpAcademicYear: 18,
    }
  },
  computed: {
    ...mapState('orders', ['orders_form']),
    ...mapState('item', ['item_form']),
  },

  watch: {
    async filterSpAcademicYear(){
      await this.GET_ABITURIENT_ORDERS_DATA(String(this.filterSpAcademicYear))
    }
  },

  methods: {
    ...mapActions('orders', ['GET_SP_ACADEMIC_YEAR', 'POST_ABITURIENT_ORDERS_DATA', 'GET_ABITURIENT_ORDERS_DATA', 'DELETE_ABITURIENT_ORDERS_DATA']),
    ...mapActions('item', ['GET_ABITURIENT_ORDERS_ITEM_DATA']),
    ...mapMutations('orders', ['UPDATE_ABITURIENT_ORDERS_DATA', 'UPDATE_ORDER_DATE']),
    ...mapMutations('item', ['SET_ABIT_ORDERS_ID']),
    convertTimestampToDate,
    openDeleteAbiturientOrdersModal(deleteAbiturientOrdersId) {
      this.deleteAbiturientOrdersId = deleteAbiturientOrdersId
    },
    async openAddAbiturientOrdersItemModal(abiturientOrdersId) {
      await this.SET_ABIT_ORDERS_ID(abiturientOrdersId)
    },
    async deleteAbiturientOrders() {
      await this.DELETE_ABITURIENT_ORDERS_DATA(this.deleteAbiturientOrdersId);
      this.$message({title: 'Удаление', text: 'Успешное удаление'})
      await this.GET_ABITURIENT_ORDERS_DATA(String(this.filterSpAcademicYear));
    },
    async submitAbiturientOrders() {

      await this.POST_ABITURIENT_ORDERS_DATA()
      await this.GET_ABITURIENT_ORDERS_DATA(String(this.filterSpAcademicYear))

    },
    changeAbiturientOrdersData(property, e, val = 'value') {
      const value = e.target[val]
      this.UPDATE_ABITURIENT_ORDERS_DATA({property, value})
    },

    changeOrderDate(property, e) {
      this.UPDATE_ORDER_DATE({property, value: e.target.value})
    },
  },
  async mounted() {
    await this.GET_SP_ACADEMIC_YEAR();
    await this.GET_ABITURIENT_ORDERS_DATA(String(this.filterSpAcademicYear))
    await this.GET_ABITURIENT_ORDERS_ITEM_DATA()
    console.log(this.orders_form.academicYear)
  }
}
</script>

<style scoped>


</style>