<template>
  <!-- Add Abit Orders Item Modal -->
  <div class="modal fade" id="addAbiturientOrdersItemModal" tabindex="-1"
       aria-labelledby="addAbiturientOrdersItemModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="addAbiturientOrdersItemModalLabel">Добавление параграфа</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md-12 mt-4">

            <div class="form-group row mt-4">
              <label for="payment_type" class="col-md-3 col-form-label">Источник
                финансирования</label>
              <div class="col-md-9">
                <select class="form-control form-select" name="payment_type" id="payment_type"
                        @input="changeAbiturientOrdersItemData( 'payment_type_id', $event)">

                  <option v-for="(paymentTypes, index) in [{id: 0, full_name: 'Выберите источник финансирования'}, ...item_form.paymentType]"
                          :value="paymentTypes.id"
                          :key="index">
                    {{paymentTypes.full_name}}
                  </option>


                </select>
              </div>
            </div>

            <div class="form-group row mt-4" v-if="item_form.itemInfos.payment_type_id == 9">
              <label for="payment_type" class="col-md-3 col-form-label">Процент скидки</label>
              <div class="col-md-9">
                <input type="number" :value="item_form.itemInfos.discount" class="form-control"
                       @input="changeAbiturientOrdersItemData( 'discount', $event)">
              </div>
            </div>

            <div class="form-group row mt-4">
              <label for="order_number" class="col-md-3 col-form-label">Текст параграфа</label>
              <div class="col-md-9">
                    <textarea id="order_number" type="text" class="form-control"
                              placeholder="Текст параграфа"
                              @input="changeAbiturientOrdersItemData( 'item_text', $event)"></textarea>
              </div>
            </div>

            <div class="form-group row mt-4">

              <div class="col-md-3">
                <p>Тип абитуриента</p>

              </div>
              <div class="col-md-9">
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2"
                         :value="1"
                         @change="changeAbiturientOrdersItemData( 'pre_university', $event)" checked>
                  <label class="form-check-label" for="flexRadioDefault2">
                    После школы | 4 года после колледжа | 4 года бакалавр
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                         :value="2"
                         @change="changeAbiturientOrdersItemData( 'pre_university', $event)">
                  <label class="form-check-label" for="flexRadioDefault1">
                    3 года после колледжа | После НИШ
                  </label>
                </div>
<!--                <div class="form-check">-->
<!--                  <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"-->
<!--                         :value="3"-->
<!--                         @change="changeAbiturientOrdersItemData( 'pre_university', $event)">-->
<!--                  <label class="form-check-label" for="flexRadioDefault1">-->
<!--                    После НИШ-->
<!--                  </label>-->
<!--                </div>-->
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault"
                         :value="4"
                         @change="changeAbiturientOrdersItemData( 'pre_university', $event)">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Магистратура
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault"
                         :value="5"
                         @change="changeAbiturientOrdersItemData( 'pre_university', $event)">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Творческий экзамен скидка
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault"
                         :value="6"
                         @change="changeAbiturientOrdersItemData( 'pre_university', $event)">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Условники
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault"
                         :value="7"
                         @change="changeAbiturientOrdersItemData( 'pre_university', $event)">
                  <label class="form-check-label" for="flexRadioDefault1">
                    3 года бакалавр
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="flexRadioDefault"
                         :value="8"
                         @change="changeAbiturientOrdersItemData( 'pre_university', $event)">
                  <label class="form-check-label" for="flexRadioDefault1">
                    Иностранец
                  </label>
                </div>
              </div>


            </div>


          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                    @click="submitAbiturientOrdersItem()">
              Добавить
            </button>
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
              Отменить
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Modal -->
</template>


<script>
import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "AddAbiturientOrdersItemModal",

  data() {
    return {

    }
  },
  computed: {
    ...mapState('item', ['item_form']),
  },


  methods: {
    ...mapActions('item', ['POST_ABITURIENT_ORDERS_ITEM_DATA', 'GET_ABITURIENT_ORDERS_ITEM_DATA', 'GET_PAYMENT_TYPE']),
    ...mapMutations('item', ['UPDATE_ABITURIENT_ORDERS_ITEM_DATA']),


    async submitAbiturientOrdersItem() {

      await this.POST_ABITURIENT_ORDERS_ITEM_DATA()
      await this.GET_ABITURIENT_ORDERS_ITEM_DATA()

    },
    changeAbiturientOrdersItemData(property, e, val = 'value') {
      const value = e.target[val]
      this.UPDATE_ABITURIENT_ORDERS_ITEM_DATA({property, value})
    },
  },
  async mounted() {
    await this.GET_PAYMENT_TYPE()
    //await this.GET_ABITURIENT_ORDERS_ITEM_DATA()
  }
}
</script>

<style scoped>


</style>